.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  background: rgb(48,54,61);
}

.logo h1{
  color:#fff;
  line-height: 64px;
  font-size: 20px;
  text-align: center;
}

.ant-menu-item-selected {
  background-color: rgb(48,54,61) !important;
}