.loginCard {
    align-items: center;
    padding: 10px 20px;
    width: 295px;
    height: 317px;
    margin: 40px auto 10px;
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
    overflow: hidden;
}

.formElements {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.label {
    margin-bottom: 6px;
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    color: #7D7D7D;
}

.loginCard h1 {
    font-weight: 700;
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 6px;
}

.login_h2_flash {
    height: 24px;
    line-height: 15px;
}

.flash {
    display: block;
    font-weight: 700;
    font-size: 0.9em;
    color: #d93439;
    text-align: center;
}

.loginCard input[type=text],
input[type=tel],
input[type=password] {
    height: 32px;
    font-size: 1em;
    font-weight: 500;
    padding-left: 8px;
    color: #131313;
    width: 200px;
    -webkit-appearance: none;
    background: #F2F2F2;
    border-radius: 3px;
    border: none;
    outline: none;
    margin-bottom: 20px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.login {
    text-align: center;
    font-size: 14px;
    font-family: 'Arial', sans-serif;
    font-weight: 600;
    padding: 0 8px;
    font-size: 1em;
    border: 0px;
    height: 36px;
    width: 100px;
    color: #fff;
    background: rgb(36,41,46);
    border-radius: 6px;
}

.login:hover {
    background: rgb(48,54,61);
}

.login:active {
    width: 94px;
    height: 32px;
}